.App {
  text-align: center;
}

.App p {
  font-size: 76px;
  color: #ff0000; /* Set text color */
}

.App h3 {
  font-size: 56px;
  color: #ff0000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  height: 100vh;
}

.App-link {
  color: #61dafb;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Spinning-image {
  animation: spin 2s linear infinite; /* Rotate clockwise indefinitely */
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}


.TwitterLogo {
  width: 64px;
  height: 64px;
  padding-left: 20px;
  padding-right: 20px;
}

.Header-content {
  display: flex;
  align-items: center; /* Center the content vertically */
  position: absolute; /* Position Header-content absolutely within App-header */
  top: 20px; /* Set top distance from the screen top */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
}

.Textboxes {
  display: none;
}

.Textboxes.visible {
  display: block; /* Show the Textboxes when the button is clicked */
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.Textboxes.hidden {
  display: none; /* Hide the Textboxes when the button is not clicked */
}

.newH2 {
  color: #ff0000;
}

.CustomButton {
  width: 200px;
  height: 40px;
  background-color: #000000;
  color: #ff0000;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

input, textarea {
  width: 200px;
  margin: 5px;
  padding: 10px; /* Add padding for better appearance */
  background-color: red; /* Set the background color to red */
  color: black; /* Set the text color to black */
  border: none; /* Remove borders */
  font-weight: bold;
}

input::placeholder, textarea::placeholder {
  color: black; /* Set the placeholder text color to black */
}

.imageContainer {
  margin-top: 40px;
  position: relative;
  z-index: 1; /* Set a higher z-index value to bring it to the top */
}

.Spinning-image {
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  padding: 30px;
}

.madimi-one-regular {
  font-family: "Micro 5", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.Centered-image img {
  object-fit: contain; /* This property prevents stretching and shrinking */
  max-width: 200%;
  max-height: 200%;
}

.Pentacle img {
  object-fit: contain; /* This property prevents stretching and shrinking */
  max-width: 200%;
  max-height: 200%;
}

.Centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* Adjust the height as needed */
  animation: moveUpDown 2s ease-in-out infinite;
  padding-top: 160px;
  cursor: pointer;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  padding-bottom: 20px;
}

.blood-drop {
  animation: moveUpDown 4s ease-in-out infinite;
}

.blood {
  padding-bottom: 20vh;
  padding-left: 4.7vh;
  padding-right: 4.7vh;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px); /* Adjust the vertical movement distance */
  }
}

h1 {
  color:#ff0000;
}

.scroll-container {
  height: 100vh; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align items horizontally */
  max-width: 1208px; /* Limit the width to 1080 pixels */
  margin: 0 auto; /* Center align items horizontally */
}

.grid-item {
  width: 128px; /* Adjust image width as needed */
  height: 128px; /* Adjust image height as needed */
  margin: 8px; /* Adjust margin between images as needed */
}

.scroll-container::-webkit-scrollbar {
  background-color: black; /* Change scrollbar background color to black */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: red; /* Change scrollbar thumb color */
}

.burn-text {
  font-weight: bolder;
}

.image-number {
  text-align: center; /* Center align the number */
  margin-top: 1px; /* Add some margin above the number */
  margin-bottom: 1px;
  font-size: 14px; /* Adjust font size as needed */
  color: red; /* Set text color to black */
}